// A component handling a list of strings used for search result exclusions.

const Vue = require('vue');
const locale = require('../../../locale');

require('./index.less');
const { confirm } = require('../../confirm');

module.exports = Vue.extend({
	template: require('./index.html'),

	data: () => ({
		selection: -1,
		modified: false,
	}),

	props: {
		items: {
			type: Array,
			required: true,
		},
	},

	ready() {},

	computed: {
		newItem() {
			return '';
		},

		valid() {
			let valid = true;
			for (const item of this.items) {
				if (!this.isValid(item)) {
					valid = false;
					break;
				}
			}
			return valid;
		},
	},

	methods: {
		onSelectItem(index) {
			if (index !== this.selection) {
				this.selection = index;
			}
		},

		onDeleteItem(index) {
			const item = this.items[index];
			Promise.resolve()
				.then(() => {
					return confirm({
						message: locale.say('Are you sure you want to delete the entry?'),
						buttonLabel: '<i class="fa fa-trash-o"></i> ' + locale.say('Delete entry'),
						buttonClass: 'danger',
					}).then(() => (this.modified = true));
				})
				.then(() => {
					this.selection = -1;
					if (item) {
						this.modified = true;
					}
					this.items.splice(index, 1);
				});
		},

		onAddEntry() {
			const index = this.items.length;
			this.items.push('');
			Vue.nextTick(() => {
				const element = this.getListElement(index);
				if (element) {
					element.scrollIntoView({
						block: 'nearest',
						behavior: 'smooth',
					});
					element.querySelector('.excluded-pattern-input input')?.focus();
				}
			});
		},

		getListElement(index) {
			return this.$el.querySelectorAll('.excluded-pattern')[index];
		},

		isValid(item) {
			return !!item;
		},
	},

	vuex: {
		actions: {},

		getters: {},
	},

	components: {},
});
