/*
Manages localizing strings, dates, and times.
*/

const jsonp = require('jsonp');
const Jed = require('jed');
const moment = require('moment');
const { isDev } = require('../utils/common');

/*
The Jed instance used to localize strings.
*/
let i18n;

function reportMissing(source) {
	if (!isDev()) {
		return;
	}
	const locale = i18n?.options?.locale;
	if (locale === 'en') {
		return;
	}
	const tx = i18n?.options?.locale_data?.messages[source];
	if (tx === undefined || tx === null) {
		console.error(`locale=${locale}: missing translation for key "${source}"`);
	}
}

const Locale = (module.exports = {
	/*
	Loads gettext strings via AJAX and sets
	*/

	loadViaAjax(locale) {
		return new Promise((resolve) => {
			/*
			If the locale is 'en' or 'en-us', return the failover data early to
			prevent unnecessary requests, especially with the online build.
			*/

			if (locale === 'en' || locale === 'en-us') {
				Locale.loadDefault();
				resolve();
				return;
			}

			// fix startup error
			if (locale.startsWith('de-')) {
				locale = 'de';
			}

			/* Attempt to fetch the locale data. */

			jsonp(`locale/${locale}.js`, { name: 'locale', timeout: 3000 }, (err, data) => {
				if (err) {
					console.warn(`Failed to load translations for locale '${locale}'. Falling back to default.`, err);
					Locale.loadDefault();
					resolve();
				} else {
					Locale.loadJson(locale, data);
					resolve();
				}
			});
		});
	},

	/*
	Loads the default locale.
	*/

	loadDefault() {
		Locale.loadJson('en', {
			domain: 'messages',
			locale_data: {
				messages: {
					'': {
						domain: 'messages',
						lang: 'en-us',
						plural_forms: 'nplurals=2; plural=(n != 1);',
					},
				},
			},
		});
	},

	/*
	Actually does setup of a locale with a name and JSON data.
	*/

	loadJson(locale, data) {
		/* Set locale in MomentJS. */

		moment.locale(locale);
		window.i18n = i18n = new Jed({ locale, ...data });
	},

	/*
	Translates a string to the user-set locale, interpolating variables.
	Anything passed beyond the source text will be interpolated into it.
	*/
	say(source, ...args) {
		try {
			const translation = i18n.gettext(source);
			try {
				reportMissing(source);
				// eslint-disable-next-line no-unused-vars
			} catch (err) {
				// ignore
			}

			if (args.length === 0) {
				return translation;
			}

			/* Interpolation required. */

			return i18n.sprintf(translation, ...args);
		} catch (e) {
			console.warn(`could not get translation for "${source}" with args ${args}:`, e);
			/*
			If all else fails, return English, even with ugly %d placeholders so
			the user can see *something*.
			*/

			return source;
		}
	},

	/**
	 * Calls translation via say but excludes calls from pot scanning ('npm run pot') because
	 * the latter looks only for 'say()' and 'translatable()'.
	 * @param source text key
	 * @param args optional additional args
	 * @return {*}
	 */
	sayNoPotScan(source, ...args) {
		// prevent say() from being scanned by pot extractor
		const sayIt = this.say;
		return sayIt(source, ...args);
	},

	/**
	 * This no-op method serves as marker in js source files which are scanned by the pot generator (npm run pot).
	 * It just returns the given textKey without calling the translation.
	 * @param textKey must be a string literal.
	 * @return {*} the given textKey
	 */
	translatable(textKey) {
		return textKey;
	},

	/*
	Translates a string to the user-set locale, keeping in mind pluralization
	rules. Any additional arguments passed after the ones listed here are
	interpolated into the resulting string.

	When interpolating, count will always be the first argument.
	*/

	sayPlural(sourceSingular, sourcePlural, count, ...args) {
		try {
			return i18n.sprintf(i18n.ngettext(sourceSingular, sourcePlural, count), count, ...args);
		} catch {
			/*
			If all else fails, return English, even with ugly placeholders
			so the user can see *something*.
			*/

			return sourcePlural.replace(/%d/g, count);
		}
	},

	date(time, format) {
		return moment(time).format(format);
	},

	time(seconds) {
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(seconds / 3600);
		return (hours > 0 ? ('0' + hours).slice(-2) + ':' : '') + ('0' + (minutes % 60)).slice(-2) + ':' + ('0' + (seconds % 60)).slice(-2);
	},
});
