const Vue = require('vue');

require('./index.less');

module.exports = Vue.extend({
	template: require('./index.html'),
	props: ['task'],

	ready() {
		// nothing to do here
	},

	methods: {
		onChange(event) {
			this.$els.text.style.height = 'auto';
			this.$els.text.style.height = `${this.$els.text.scrollHeight}px`;
		},
	},
});
