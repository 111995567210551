// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../common/img/suggestions.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../common/img/edit.svg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../common/img/colors.svg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../common/img/regular-tag.svg");
var ___HTML_LOADER_IMPORT_4___ = require("../../../../common/img/delete.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<drop-down position=\"bottom left\" class=\"tags\">\n\t<div class=\"menu\">\n\t\t<div class=\"menu__item\" @click=\"suggestions\">\n\t\t\t<span class=\"menu__item--icon\">\n\t\t\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\">\n\t\t\t</span>\n\t\t\t<span class=\"menu__item--text\">{{ 'Show alternatives' | say }}</span>\n\t\t</div>\n\t\t<div class=\"menu__item\" @click=\"change\">\n\t\t\t<span class=\"menu__item--icon\">\n\t\t\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\">\n\t\t\t</span>\n\t\t\t<span class=\"menu__item--text\">{{ 'Edit' | say }}</span>\n\t\t</div>\n\t\t<div class=\"menu__item\">\n\t\t\t<span class=\"menu__item--icon\">\n\t\t\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\">\n\t\t\t</span>\n\t\t\t<span class=\"menu__item--text\">{{ 'Change Color' | say }}</span>\n\t\t\t<span class=\"menu__item--caret\">\n\t\t\t\t<i class=\"fa fa-caret-right\"></i>\n\t\t\t</span>\n\t\t\t<tag-colors :tag=\"tag\" :story-id=\"storyId\"></tag-colors>\n\t\t</div>\n\t\t<div class=\"menu__item\">\n\t\t\t<span class=\"menu__item--icon\">\n\t\t\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\">\n\t\t\t</span>\n\t\t\t<span class=\"menu__item--text\">{{ 'Change Tag Type' | say }}</span>\n\t\t\t<span class=\"menu__item--caret\">\n\t\t\t\t<i class=\"fa fa-caret-right\"></i>\n\t\t\t</span>\n\t\t\t<tag-types :tag=\"tag\" :passage=\"passage\" :story-id=\"storyId\"></tag-types>\n\t\t</div>\n\t\t<div class=\"menu__item\" @click=\"remove\">\n\t\t\t<span class=\"menu__item--icon\">\n\t\t\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\">\n\t\t\t</span>\n\t\t\t<span class=\"menu__item--text\">{{ 'Delete' | say }}</span>\n\t\t</div>\n\t</div>\n</drop-down>";
// Exports
module.exports = code;