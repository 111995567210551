const isDev = () => process.env.NODE_ENV === 'development';

const isLocal = () => localStorage.getItem('DEV_ENV') === 'true';

const trim = (str) => (str === null ? null : str?.replace(/^[\n\r\s]+/, '').replace(/[\n\r\s]+$/, ''));

const isEmpty = (obj) => !Object.values(obj).some((value) => (value != null && typeof value === 'object' ? !isEmpty(value) : !!value));

const isValidUrl = (url) => {
	try {
		new URL(url);
		if (!/^(https?|ftps?):\/\/.+/.test(url)) {
			return false;
		}
		return !(process.env.NODE_ENV === 'production' && isLocalNetworkUrl(url));
	} catch {
		return false;
	}
};

const isLocalNetworkUrl = (url) => {
	const localNetworkPatterns = [/^http:\/\/192\.168\.\d{1,3}\.\d{1,3}/, /^http:\/\/10\.\d{1,3}\.\d{1,3}\.\d{1,3}/, /^http:\/\/127\.0\.0\.1/];
	return localNetworkPatterns.some((pattern) => pattern.test(url));
};

const absoluteUrl = (host, path) => {
	if (isValidUrl(path)) {
		return path;
	}
	const url = new URL(host);
	let uri = url.protocol + '//' + url.host;
	if (path.startsWith('/')) {
		return uri + path;
	} else {
		const pathname = String(url.pathname);
		return uri + pathname.substring(0, pathname.lastIndexOf('/')) + '/' + path;
	}
};

const camelToKebab = (str) => str.replace(/[A-Z]/g, (c) => `-${c.toLowerCase()}`);

/**
 * Returns a string from a Date object of the pattern YYY-MM-DD
 * @param {Date} date
 * @returns {String} YYYY-MM-DD
 */
const stringFromDate = (date) => date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

const regularExpression = (str, flags) => new RegExp(str.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1'), flags);

const queryParams = (obj) =>
	Object.entries(obj)
		.map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
		.join('&');

module.exports = {
	trim,
	isDev,
	isLocal,
	isEmpty,
	isValidUrl,
	absoluteUrl,
	camelToKebab,
	stringFromDate,
	regularExpression,
	queryParams,
};
