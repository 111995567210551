// A component showing a modal dialog where a story's JavaSCript.

const Vue = require('vue');
const { deleteStory, translateParentStory, addChildTranslations, updateChildStoryTranslation } = require('../../data/actions/story');

require('./index.less');
const { playStory } = require('../../common/launch-story');
const { confirm } = require('../confirm');
const locale = require('../../locale');
const escape = require('lodash.escape');

module.exports = Vue.extend({
	template: require('./index.html'),

	data: function () {
		return {
			storyId: null,
			isLoading: false,
			errorMessage: '',
		};
	},

	computed: {
		parentTranslation() {
			const story = this.getStoryById(this.storyId);
			return story.parentTranslation || {};
		},
		childTranslations() {
			const story = this.getStoryById(this.storyId);
			return story.childTranslations || [];
		},
		hasChildTranslations() {
			const story = this.getStoryById(this.storyId);
			const childTranslations = story.childTranslations || [];
			return childTranslations.length > 0;
		},
		hasParentTranslation() {
			const story = this.getStoryById(this.storyId);
			return story.parentTranslation && story.parentTranslation.id !== undefined;
		},
	},

	methods: {
		createTranslation() {
			this.isLoading = true; // Start loading
			this.errorMessage = ''; // Reset error message

			this.translateParentStory(this.storyId)
				.then(() => {
					this.addChildTranslations(this.storyId);
				})
				.catch((error) => {
					this.errorMessage = error.message;
				})
				.finally(() => {
					this.isLoading = false; // Stop loading
				});
		},

		playTranslation(id) {
			playStory(this.$store, id, '_blank');
		},

		updateTranslation(id) {
			const story = this.getStoryById(id);

			this.isLoading = true; // Start loading
			this.errorMessage = ''; // Reset error message

			this.updateChildStoryTranslation(story.id)
				.catch((error) => {
					this.errorMessage = error.message;
				})
				.finally(() => {
					this.isLoading = false; // Stop loading
				});
		},

		deleteTranslation(id) {
			const story = this.getStoryById(id);
			confirm({
				message: locale.say('Are you sure you want to delete &ldquo;%s&rdquo;?<br>This cannot be undone.', escape(story.name)),
				buttonLabel: '<i class="fa fa-trash-o"></i> ' + locale.say('Delete Permanently'),
				buttonClass: 'danger',
			}).then(() => this.deleteStory(id));
		},
	},

	vuex: {
		actions: {
			deleteStory,
			translateParentStory,
			addChildTranslations,
			updateChildStoryTranslation,
		},
		getters: {
			allStories: (state) => state.story.stories,
			getStoryById: (state) => (id) => {
				return state.story.stories.find((story) => story.id === id);
			},
		},
	},

	components: {
		'modal-dialog': require('../../ui/modal-dialog'),
	},
});
