/*
Story-related actions.
*/
const { translateMenu, translateAIPrompts, translateXmlPassages, translateTagColors } = require('@das-buro-am-draht/chatbottery-translate');

const semverUtils = require('semver-utils');
const { latestFormatVersions, formatVersion } = require('../format-versions');
const { getStory } = require('../../utils/stories');
const { filterTranslatablePassages } = require('../../utils/passages');
const isEqual = require('lodash.isequal');

const parentBotLanguage = 'de-DE';
const parentBotLanguageLabel = new Intl.DisplayNames([parentBotLanguage], {
	type: 'language',
}).of(parentBotLanguage);
const childBotLanguage = 'en-GB';
const childBotLanguageLabel = new Intl.DisplayNames([parentBotLanguage], {
	type: 'language',
}).of(childBotLanguage);

const actions = (module.exports = {
	createStory(store, props) {
		let normalizedProps = Object.assign({}, props);

		/* If a format isn't specified, use the default one. */

		if (!normalizedProps.storyFormat) {
			normalizedProps.storyFormat = store.state.pref.defaultFormat.name;
			normalizedProps.storyFormatVersion = store.state.pref.defaultFormat.version;
		}

		store.dispatch('CREATE_STORY', normalizedProps);
	},

	updateStory({ dispatch }, id, props) {
		dispatch('UPDATE_STORY', id, props);
	},

	deleteStory({ dispatch }, id) {
		dispatch('DELETE_STORY', id);
	},

	duplicateStory({ dispatch }, id, newName) {
		dispatch('DUPLICATE_STORY', id, newName);
	},

	importStory(store, toImport) {
		const story = Object.assign({}, toImport);
		if (!formatVersion(store.state.storyFormat.formats, story.storyFormat, story.storyFormatVersion)) {
			story.storyFormat = store.state.pref.defaultFormat.name;
			story.storyFormatVersion = store.state.pref.defaultFormat.version;
		}
		store.dispatch('IMPORT_STORY', story);
	},

	setTagColorInStory(store, storyId, tagName, tagColor) {
		const story = store.state.story.stories.find((story) => story.id === storyId);
		if (!story) {
			throw new Error(`No story exists with id ${storyId}`);
		}

		const toMerge = { [tagName]: tagColor === 'grey' ? undefined : tagColor };

		store.dispatch('UPDATE_STORY', storyId, {
			tagColors: { ...story.tagColors, ...toMerge },
		});
	},

	async translateParentStory({ dispatch, state }, storyId) {
		const parentStory = getStory(state.story.stories, storyId);

		const childStoryTranslation = JSON.parse(JSON.stringify(parentStory));

		try {
			childStoryTranslation.passages = await translateXmlPassages(parentStory.passages, parentStory.settings.proxyUrl, childBotLanguage);
			childStoryTranslation.tagColors = await translateTagColors(parentStory.tagColors, parentStory.settings.proxyUrl, childBotLanguage);
		} catch (error) {
			console.error('Error translating passages / tagColors:', error);
		}

		try {
			childStoryTranslation.script = await translateMenu(childStoryTranslation.script, parentStory.settings.proxyUrl, childBotLanguage);
			childStoryTranslation.script = await translateAIPrompts(childStoryTranslation.script, parentStory.settings.proxyUrl, childBotLanguage);
		} catch (error) {
			console.warn('Error translating custom js:', error);
		}

		childStoryTranslation.lastUpdate = new Date();
		childStoryTranslation.name = `${parentStory.name}-${childBotLanguage}`;
		childStoryTranslation.parentTranslation = {
			id: parentStory.id,
			locale: parentBotLanguage,
			label: parentBotLanguageLabel,
		};

		dispatch('SAVE_STORY_TRANSLATION', childStoryTranslation);
	},

	async updateChildStoryTranslation({ dispatch, state }, storyId) {
		const childStory = getStory(state.story.stories, storyId);
		if (!childStory.parentTranslation) {
			throw new Error(`Child story id ${childStory.id} has no parent translation`);
		}
		const parentStory = getStory(state.story.stories, childStory.parentTranslation.id);

		const parentStoryTranslation = JSON.parse(JSON.stringify(parentStory));

		const translatablePassages = filterTranslatablePassages(parentStory.passages, childStory.passages);
		try {
			parentStoryTranslation.passages = await translateXmlPassages(translatablePassages, parentStory.settings.proxyUrl, childBotLanguage);
			parentStoryTranslation.tagColors = await translateTagColors(parentStory.tagColors, parentStory.settings.proxyUrl, childBotLanguage);
		} catch (error) {
			console.error('Error translating parent story passages / tagColors:', error);
		}

		let updatedTagsInPassages = false;
		parentStoryTranslation.passages.forEach((parentPassage) => {
			const childPassage = childStory.passages.find((childPassage) => childPassage.name === parentPassage.name);
			if (childPassage) {
				updatedTagsInPassages ||= !isEqual(parentPassage.tags, childPassage.tags);

				dispatch('UPDATE_PASSAGE_IN_STORY', childStory.id, childPassage.id, {
					text: parentPassage.text,
					tags: parentPassage.tags,
				});
			} else {
				if (parentPassage.tags.length > 0) {
					updatedTagsInPassages = true;
				}
				// eslint-disable-next-line no-unused-vars
				const { id, ...restProps } = parentPassage;
				dispatch('CREATE_PASSAGE_IN_STORY', childStory.id, {
					...restProps,
				});
			}
		});

		if (updatedTagsInPassages) {
			dispatch('UPDATE_STORY', childStory.id, {
				tagColors: {
					...childStory.tagColors,
					...parentStoryTranslation.tagColors,
				},
			});
		}
	},

	addChildTranslations({ dispatch, state }, storyId) {
		const parent = getStory(state.story.stories, storyId);
		const child = state.story.stories.find((story) => story.parentTranslation?.id === storyId);

		const childTranslation = {
			id: child.id,
			locale: childBotLanguage,
			label: childBotLanguageLabel,
		};
		const childTranslations = parent.childTranslations || [];
		childTranslations.push(childTranslation);

		dispatch('UPDATE_STORY', parent.id, {
			childTranslations,
		});
	},

	/*
	Removes any unused tag colors from a story.
	*/

	cleanUpTagColorsInStory(store, storyId) {
		let story = store.state.story.stories.find((story) => story.id === storyId);
		let tagColors = Object.assign({}, story.tagColors);

		if (!story) {
			throw new Error(`No story exists with id ${storyId}`);
		}

		Object.keys(tagColors).forEach((tag) => {
			if (story.passages.some((p) => p.tags.indexOf(tag) !== -1)) {
				return;
			}

			delete tagColors[tag];
		});

		store.dispatch('UPDATE_STORY', storyId, { tagColors });
	},

	/*
	Repairs stories by ensuring that they always have a story format and
	version set.
	*/

	repairStories(store) {
		const latestVersions = latestFormatVersions(store);

		store.state.story.stories.forEach((story) => {
			/*
			Reset stories without any story format.
			*/

			if (!story.storyFormat) {
				actions.updateStory(store, story.id, {
					storyFormat: store.state.pref.defaultFormat.name,
				});
			}

			/*
			Coerce old SugarCube formats, which had version numbers in their
			name, to the correct built-in ones.
			*/

			if (/^SugarCube 1/.test(story.storyFormat)) {
				actions.updateStory(store, story.id, {
					storyFormat: 'SugarCube',
					storyFormatVersion: latestVersions['SugarCube']['1'].version,
				});
			} else if (/^SugarCube 2/.test(story.storyFormat)) {
				actions.updateStory(store, story.id, {
					storyFormat: 'SugarCube',
					storyFormatVersion: latestVersions['SugarCube']['2'].version,
				});
			}

			if (story.storyFormatVersion) {
				/*
				Update the story's story format to the latest available version.
				*/

				const majorVersion = semverUtils.parse(story.storyFormatVersion).major;

				if (
					latestVersions[story.storyFormat] &&
					latestVersions[story.storyFormat][majorVersion] &&
					story.storyFormatVersion !== latestVersions[story.storyFormat][majorVersion].version
				) {
					actions.updateStory(store, story.id, {
						storyFormatVersion: latestVersions[story.storyFormat][majorVersion].version,
					});
				}
			} else if (latestVersions[story.storyFormat]) {
				/*
				If a story has no format version, pick the lowest major version
				number currently available.
				*/

				const majorVersion = Object.keys(latestVersions[story.storyFormat]).reduce((prev, current) => (+current < +prev ? +current : +prev));

				actions.updateStory(store, story.id, {
					storyFormatVersion: latestVersions[story.storyFormat][majorVersion].version,
				});
			}
		});
	},
});
