// A component showing a modal dialog where a story's JavaSCript.

const Vue = require('vue');
const { updateStory } = require('../../data/actions/story');
const { isValidUrl } = require('../../utils/common');
const locale = require('../../locale');
const { confirm } = require('../confirm');

require('./index.less');

module.exports = Vue.extend({
	template: require('./index.html'),

	data: () => ({
		storyId: null,
		assetBaseUrl: '',
		storyUrl: '',
		searchUrl: '',
		proxyUrl: '',
		embedPDF: false,
		aiResultDisclaimer: '',
		enableAiResultFeedback: false,
		modified: false,
		excludedSearchUrlPatterns: [],
	}),

	ready() {
		const data = this.settingsData;
		if (data) {
			this.assetBaseUrl = data.assetBaseUrl || '';
			this.storyUrl = data.storyUrl || '';
			this.proxyUrl = data.proxyUrl || 'https://proxy.chatbottery.com/';
			this.searchUrl = data.searchUrl || '';
			this.embedPDF = data.embedPDF || false;
			this.aiResultDisclaimer = data.aiResultDisclaimer || '';
			this.enableAiResultFeedback = data.enableAiResultFeedback || false;
			this.excludedSearchUrlPatterns = data.excludedSearchUrlPatterns || [];
		}
		this.$watch('assetBaseUrl', () => (this.modified = true));
		this.$watch('storyUrl', () => (this.modified = true));
		this.$watch('proxyUrl', () => (this.modified = true));
		this.$watch('searchUrl', () => (this.modified = true));
		this.$watch('embedPDF', () => (this.modified = true));
		this.$watch('aiResultDisclaimer', () => (this.modified = true));
		this.$watch('enableAiResultFeedback', () => (this.modified = true));
		this.$watch('excludedSearchUrlPatterns', () => (this.modified = true));
	},

	computed: {
		settingsData() {
			const { settings } = this.allStories.find((story) => story.id === this.storyId) || {};
			return settings;
		},
		isValid() {
			return this.isValidUrl(this.storyUrl) && this.isValidBaseUrl(this.assetBaseUrl);
		},
	},

	methods: {
		isValidUrl(url) {
			return !url || isValidUrl(url);
		},
		isValidBaseUrl(url) {
			return this.isValidUrl(url) && (!url || /\/$/.test(url));
		},
		save() {
			const settings = {
				...this.settingsData,
				storyUrl: this.storyUrl,
				searchUrl: this.searchUrl,
				proxyUrl: this.proxyUrl,
				assetBaseUrl: this.assetBaseUrl,
				embedPDF: this.embedPDF,
				aiResultDisclaimer: this.aiResultDisclaimer,
				enableAiResultFeedback: this.enableAiResultFeedback,
				excludedSearchUrlPatterns: this.excludedSearchUrlPatterns,
			};
			this.updateStory(this.storyId, { settings });
			this.modified = false;
			this.$refs.modal.close();
		},

		canClose() {
			if (!this.modified) {
				return true;
			}
			confirm({
				message: locale.say('There were changes detected for the settings dialog. Are you sure you want to discard those changes?'),
				buttonLabel: '<i class="fa fa-trash-o"></i> ' + locale.say('Discard changes'),
				buttonClass: 'danger',
			}).then(() => {
				this.$refs.modal.$emit('close');
			});
			return false;
		},
	},

	vuex: {
		actions: {
			updateStory,
		},
		getters: {
			allStories: (state) => state.story.stories,
		},
	},

	components: {
		'modal-dialog': require('../../ui/modal-dialog'),
		'exclusion-list': require('./exclusion-list'),
	},
});
