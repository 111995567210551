// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../common/img/new-window-icon.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../../common/img/logo-chatbottery.png");
var ___HTML_LOADER_IMPORT_2___ = require("../../common/img/plus-icon.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div class=\"infoContent\">\n    <div class=\"infoContent--hero\">\n\t\t<header class=\"infoContent--header\">\n\t\t\t<div class=\"infoContent--description\">\n\t\t\t\t{{ 'Meet our award winning chatbot software. Made with' | say }} <i class=\"fa fa-heart\" aria-hidden=\"true\"></i> + <a href=\"https://twinery.org/\" target=\"_blank\">Twinery</a>. \n\t\t\t</div>\n\t\t\t<button class=\"infoContent--nav-item\" @click=\"openRedirect('https://chatbottery.com/')\">\n\t\t\t\t<div class=\"infoContent--text\">{{ 'About us' | say }}</div>\n\t\t\t\t<div class=\"infoContent--redirect\">\n\t\t\t\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" />\n\t\t\t\t</div>\n\t\t\t</button>\n\t\t</header>\n\t\t<div class=\"infoContent--logo\">\n\t\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" />\n\t\t</div>\n\t\t<div class=\"infoContent--version\">\n\t\t\tEditor version <span>{{ appInfo.version }}</span>\n\t\t</div>\n\t\t<footer class=\"infoContent--footer\">\n\t\t\t<button class=\"infoContent--new-chatbot\" :title=\"'New chatbot' | say\" @click=\"createStoryPrompt\">\n\t\t\t\t<span>{{ 'Create a Chatbot' | say }}</span>\n\t\t\t\t<img class=\"icon\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" />\n\t\t\t</button>\n\t\t</footer>\n\t</div>\n\t<wordpress-news></wordpress-news>\n</div>\n";
// Exports
module.exports = code;