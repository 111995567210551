const Vue = require('vue');
const { say } = require('../../../../locale');

module.exports = Vue.extend({
	template: require('./index.html'),
	props: {
		task: {
			type: Object,
			required: true,
		},
		story: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		label: '',
		site: '',
		query: '',
		template: '',
		searchtype: '',
		searchEngine: '',
		fallbackEngine: '',
		searchEngineOptions: [],
		fallbackEngineOptions: [],
	}),

	ready() {
		const haveSolr = this.story.plugins.solr !== undefined;
		const bingOption = { value: 'bing', text: say('Web Search') };
		this.searchEngineOptions = [bingOption];
		this.fallbackEngineOptions = [{ value: '', text: say('None') }, bingOption];
		if (haveSolr) {
			const solrOption = { value: 'solr', text: say('Custom Solr Search') };
			this.searchEngineOptions.push(solrOption);
			this.fallbackEngineOptions.push(solrOption);
		}
		const label = (this.task.opt || [])[0] || '';
		if (label !== this.label) {
			this.label = label;
		}
		const site = this.task.attributes['site'] || '';
		if (site !== this.site) {
			this.site = site;
		}
		const query = this.task.attributes['query'] || '';
		if (query !== this.query) {
			this.query = query;
		}
		const template = this.task.attributes['template'] || '';
		if (template !== this.template) {
			this.template = template;
		}
		const searchtype = this.task.attributes['searchtype'] || '';
		if (searchtype !== this.searchtype) {
			this.searchtype = searchtype;
		}
		const searchEngine = this.task.attributes['searchengine'] || '';
		if (searchEngine !== this.searchEngine) {
			this.searchEngine = searchEngine;
		}
		const fallbackEngine = this.task.attributes['fallbackengine'] || '';
		if (fallbackEngine !== this.fallbackEngine) {
			this.fallbackEngine = fallbackEngine;
		}
	},

	methods: {
		onChangeLabel(_event) {
			this.task.opt = this.label ? [this.label] : [];
		},
		onChangeSite(_event) {
			this.task.attributes['site'] = this.site;
		},
		onChangeQuery(_event) {
			this.task.attributes['query'] = this.query;
		},
		onChangeTemplate(_event) {
			this.task.attributes['template'] = this.template;
		},
		onChangeSearchType(_event) {
			this.task.attributes['searchtype'] = this.searchtype;
		},
		onChangeSearchEngine(_event) {
			this.task.attributes['searchengine'] = this.searchEngine;
		},
		onChangeFallbackEngine(_event) {
			this.task.attributes['fallbackengine'] = this.fallbackEngine;
		},
	},

	components: {},
});
