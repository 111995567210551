/*
A Vuex module for working with stories. This is meant to be incorporated by
index.js.
*/

const uuid = require('tiny-uuid');
const locale = require('../../locale');
const idFor = require('../id');
const { getStoryInState } = require('../../utils/stories');

function getPassageInStory(story, id) {
	let passage = story.passages.find((passage) => passage.id === id);

	if (!passage) {
		throw new Error(`No passage exists in this chatbot with id ${id}`);
	}

	return passage;
}

const storyStore = (module.exports = {
	state: {
		stories: [],
	},

	mutations: {
		CREATE_STORY(state, props) {
			let story = Object.assign(
				{
					id: idFor(props.name),
					lastUpdate: new Date(),
					ifid: uuid().toUpperCase(),
					tagColors: {},
					passages: [],
					settings: {},
					plugins: {},
					userData: {},
					externalData: [],
				},
				storyStore.storyDefaults,
				props
			);

			if (story.passages) {
				story.passages.forEach((passage) => (passage.story = story.id));
			}

			state.stories.push(story);
		},

		UPDATE_STORY(state, id, props) {
			let story = getStoryInState(state, id);

			Object.assign(story, props);
			story.lastUpdate = new Date();
		},

		DUPLICATE_STORY(state, id, newName) {
			const original = getStoryInState(state, id);

			let story = Object.assign({}, original, {
				id: idFor(newName),
				ifid: uuid().toUpperCase(),
				name: newName,
			});

			/* We need to do a deep copy of the passages. */

			story.passages = [];

			original.passages.forEach((originalPassage) => {
				const passage = Object.assign({}, originalPassage, {
					id: idFor(newName + originalPassage.name),
					story: story.id,
				});

				if (passage.tags) {
					passage.tags = originalPassage.tags.slice(0);
				}

				if (passage.flags) {
					passage.flags = originalPassage.flags.slice(0);
				}

				if (original.startPassage === originalPassage.id) {
					story.startPassage = passage.id;
				}

				story.passages.push(passage);
			});

			state.stories.push(story);
		},

		IMPORT_STORY(state, toImport) {
			/*
			See data/import/import.js for how the object that we receive is
			structured.

			Assign IDs to to everything, link passages to their story,
			and set the story's startPassage property appropriately.
			*/

			toImport.id = idFor(toImport.name);

			toImport.passages.forEach((p) => {
				p.id = idFor(toImport.name + p.name);
				p.story = toImport.id;

				if (p.pid === toImport.startPassagePid) {
					toImport.startPassage = p.id;
				}

				delete p.pid;
			});

			delete toImport.startPassagePid;
			state.stories.push(toImport);
		},

		DELETE_STORY(state, id) {
			const storyToDelete = state.stories.find((story) => story.id === id);
			const parentStoryId = storyToDelete?.parentTranslation?.id;
			if (parentStoryId) {
				const parentStory = state.stories.find((story) => story.id === parentStoryId);
				// TODO: check if this can happen?!
				if (parentStory) {
					const childTranslations = parentStory.childTranslations || [];
					parentStory.childTranslations = childTranslations.filter((child) => child.id !== storyToDelete.id);
				}
			}

			state.stories = state.stories.filter((story) => story.id !== id);
		},

		CREATE_PASSAGE_IN_STORY(state, storyId, props) {
			/*
			uuid is used here as a salt so that passages always contain unique
			IDs in Electron (which otherwise uses deterministic IDs based on
			name provided), even if you rename one to a name a previous one used
			to have.
			*/

			let story = getStoryInState(state, storyId);
			let newPassage = Object.assign(
				{
					id: idFor(story.name + uuid()),
				},
				storyStore.passageDefaults,
				props
			);

			/*
			Force the top and left properties to be at least zero, to keep
			passages onscreen.
			*/

			if (newPassage.left < 0) {
				newPassage.left = 0;
			}

			if (newPassage.top < 0) {
				newPassage.top = 0;
			}

			newPassage.story = story.id;
			story.passages.push(newPassage);

			if (story.passages.length === 1) {
				story.startPassage = newPassage.id;
			}

			story.lastUpdate = new Date();
		},

		UPDATE_PASSAGE_IN_STORY(state, storyId, passageId, props) {
			let story;

			try {
				story = getStoryInState(state, storyId);
			} catch {
				return;
			}

			/*
			Force the top and left properties to be at least zero, to keep
			passages onscreen.
			*/

			if (props.left && props.left < 0) {
				props.left = 0;
			}

			if (props.top && props.top < 0) {
				props.top = 0;
			}

			let passage;

			try {
				passage = getPassageInStory(story, passageId);
			} catch {
				return;
			}

			Object.assign(passage, props);
			story.lastUpdate = new Date();
		},

		DELETE_PASSAGE_IN_STORY(state, storyId, passageId) {
			let story = getStoryInState(state, storyId);

			story.passages = story.passages.filter((passage) => passage.id !== passageId);
			story.lastUpdate = new Date();
		},

		SAVE_STORY_TRANSLATION(state, story) {
			story.id = idFor(story.name);
			story.ifid = uuid().toUpperCase();
			story.passages.forEach((passage) => {
				const originalId = passage.id;
				passage.id = idFor(story.name + passage.name);
				passage.story = story.id;
				if (story.startPassage === originalId) {
					story.startPassage = passage.id;
				}
			});

			state.stories.push(story);
		},
	},

	/* Defaults for newly-created objects. */

	storyDefaults: {
		name: locale.translatable('Untitled Story'),
		startPassage: -1,
		zoom: 1,
		snapToGrid: false,
		stylesheet: '',
		script: '',
		storyFormat: '',
		storyFormatVersion: '',
	},

	passageDefaults: {
		story: -1,
		top: 0,
		left: 0,
		width: 150,
		height: 100,
		tags: [],
		flags: [],
		name: locale.translatable('Untitled Passage'),
		selected: false,
		text: '', // set empty text to avoid translation issues
	},
});
