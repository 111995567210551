const getProxyUrl = (url) => {
	if (url) {
		return url.endsWith('/') ? url : `${url}/`;
	}

	return url ? url : 'https://proxy.chatbottery.com/';
};

const setFlag = (flags, flag, boolValue) => {
	let updatedFlags = true;

	if (boolValue && !flags.includes(flag)) {
		flags.push(flag);
	} else if (!boolValue && flags.includes(flag)) {
		const index = flags.indexOf(flag);
		flags.splice(index, 1);
	} else {
		updatedFlags = false;
	}

	return updatedFlags;
};

module.exports = {
	getProxyUrl,
	setFlag,
};
