const Vue = require('vue');

module.exports = Vue.extend({
	template: require('./index.html'),
	props: {
		task: {
			type: Object,
			required: true,
		},
		story: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		attributes: {},
		image: '',
		assetBaseUrl: '',
		altText: '',
	}),

	ready() {
		// Copy the attributes from the task to the local data object
		// to be able to track changes
		this.attributes = { ...this.task.attributes };

		if (this.story) {
			const { settings } = this.story;
			if (settings?.assetBaseUrl) {
				this.assetBaseUrl = settings.assetBaseUrl;
			}
		}

		const image = this.attributes['img'] || '';
		if (image !== this.image) {
			this.image = image;
		}

		const altText = this.attributes['alttext'] || '';
		if (altText !== this.altText) {
			this.altText = altText;
		}
	},

	methods: {
		onChange(event) {
			this.attributes['img'] = this.image;
			this.attributes['alttext'] = this.altText;
			// Copy the attributes back to the task to persist the changes
			this.task.attributes = { ...this.attributes };
		},
	},

	components: {
		'image-placeholder': require('../../../../ui/image-placeholder'),
		'task-options': require('../options'),
	},
});
