const getStory = (stories, id) => {
	return stories.find((story) => story.id === id);
};

/*
A shorthand function for finding a particular story in the state, first by searching
in state.stories[storyId] and then in state.story.stories[storyId].
*/
const getStoryInState = (state, storyId) => {
	let story;
	if (state.stories) {
		story = getStory(state.stories, storyId);
	} else if (state.story && state.story.stories) {
		story = getStory(state.story.stories, storyId);
	}

	if (!story) {
		throw new Error(`No chatbot exists with id ${storyId}`);
	}

	return story;
};

module.exports = {
	getStory,
	getStoryInState,
};
