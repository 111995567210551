const { getStoryInState } = require('./stories');

const getPassage = (passages, id) => passages.find((passage) => passage.id === id);

const getPassageInState = (state, storyId, passageId) => {
	const story = getStoryInState(state, storyId);
	const passage = getPassage(story.passages, passageId);

	if (!passage) {
		throw new Error(`No passage exists in this chatbot with id ${passageId}`);
	}

	return passage;
};

const FLAG_DONT_TRANSLATE = 'dontTranslate';

const excludedFromTranslation = (passage) => passage?.flags.includes(FLAG_DONT_TRANSLATE);

const shouldTranslatePassage = (passage, otherPassage) => !(excludedFromTranslation(passage) || excludedFromTranslation(otherPassage));

const filterTranslatablePassages = (passages, otherPassages) => {
	return passages.filter((passage) => {
		const otherPassage = otherPassages.find((p) => p.name === passage.name);
		return shouldTranslatePassage(passage, otherPassage);
	});
};

export { FLAG_DONT_TRANSLATE, getPassage, getPassageInState, filterTranslatablePassages, excludedFromTranslation, shouldTranslatePassage };
